import App from "./App";
import { createRoot } from "react-dom/client";
import "./assets/scss/index.scss";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/auth-contex";

const dejan = "http://192.168.1.223:8000/api/v1/";
const nenad = "http://192.168.1.113:8000/api/v1/";
const api = "https://api.scoring.croonus.com/api/v1/";

if (process.env.REACT_APP_URL || api) {
    localStorage.setItem("api", process.env.REACT_APP_URL || api);

    createRoot(document.getElementById("root")).render(
        <AuthContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthContextProvider>,
    );
} else {
    createRoot(document.getElementById("root")).render(<h1>Required env variable is not set: REACT_APP_URL</h1>);
}
